'use strict';
var pikaday = require('pikaday');
var pikaday_jquery = require('pikaday-jquery');

var gestures = require("./build/gestures");

var moment = require('moment');
var momentDe = require('moment/locale/de');
moment.locale('de');
window.moment = moment;

var dt = require( 'datatables.net' )( window, $ );
var dt_net = require( 'datatables.net-dt' )( window, $ );
var dt_select = require( 'datatables.net-select' )( window, $ );
var dt_select_net = require( 'datatables.net-select-dt' )( window, $ );
//var edit_tables = require( './edit-tables' )();
var dt_uikit = require( './build/datatables.uikit' )( window, $ );